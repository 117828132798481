@import "../../../constants/style.scss";
.loaderMain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  pointer-events: auto;
  display: flex;
  align-items: center;
}

.loader {
  width: 6em;
  height: 6em;
  background: $my-color1;
  border-radius: 50%;
  margin: 5em auto;
  border: 0.3em solid transparent;
  animation: glow 1s ease infinite;
  z-index: 1;
}

.debugURL {
  position: fixed;
  top: 20%;
  left: 40%;
  color: $my-black-color;
  font-size: 2.5rem;
  background-color: $my-background-color;
  border-radius: 10px;
  padding: 5px;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0 0.4em $my-color1, 0 0 0 0.1em $my-color2;
    transform: rotate(360deg);
  }
  50% {
    border-top-color: $my-color2;
  }
  100% {
    box-shadow: 0 0 0 0.4em $my-color1, 0 0 0 3.6em transparent;
  }
}
