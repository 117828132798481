.inputFile { 
    display: none; 
  } 
   
  .inputFileLabel { 
   // / Define the styles for your custom file input / 
    margin: 5px; 
    min-width: 200px; 
    min-height: 60px; 
    height: 4.5vh; 
    width: 12vw; 
    font-size: 1.7rem; 
    cursor: pointer; 
    outline: none; 
    color: white; 
    border: none; 
    border-radius: 7px; 
    box-shadow: 0 4px #08bd55; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    overflow: hidden; 
    background-color: #08bd55; 
    // Apply the background color / 
  } 
   
  .inputFileLabel:hover { 
    background-color: #08bd55; 
  } 
   
  .inputFileLabel:active { 
    background-color: #08bd55; 
    box-shadow: 0 3px #666; 
    transform: translateY(4px); 
  } 
   
  .inputFileRow { 
    display: flex; 
    flex-direction: row; 
  } 
   
  .withGap { 
    margin-right: 10px; 
  } 
   
  h2 { 
    padding-top: 20px; 
    padding-left: 3px; 
    padding-right: 5px; 
    font-size: 2em;  
  } 
   
  .tableContainer { 
    max-height: 430px; 
    overflow-y: auto; 
  } 
   
  .itemGrid { 
    width: 100%; 
    border-collapse: collapse; 
    margin-top: 10px; 
   
    th, td { 
      border: 1px solid #ddd; 
      padding: 8px; 
      text-align: left; 
    } 
   
    th { 
      background-color: #f2f2f2; 
    } 
  }