$my-color1: #f7931e;
$my-color2: #29abe2;
$my-color2_1: #2874a6;
$my-color3: #566573 ;
$my-black-color: #252525;
$my-white-color: #f0f0d6;
$my-background-color: #d6eaf8;
$my-required-background-color: #fdedec;
$my-disabled-color: #ebedef;
$my-grey-color: #ccc;
$my-danger-color: #e74c3c;
$my-danger-color_1: #943126;
$my-danger-color_2: #9e190a;
$my-green-color: #28B463;
$my-green-color_1: #186A3B;
$my-green-color_1_1: #0f4828;