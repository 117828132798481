.containerOverviewCustomCard {
  padding: 20px;
}
.overviewCustomCard {
  border: 1px solid #dee2e6 !important;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 2rem 1rem;
  /* margin: 1rem 0; */
  /* width: 30%; */
  height: 200px;
  cursor: pointer;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-width: 250px; */
  /* margin-right: 20px; */
}

.overviewCustomCard h5{
  margin-bottom: 2px;
}

.overviewCustomCard p {
  margin-bottom: 0;
  margin-left: 10px;
}

.overviewCardContainer {
  max-width: 100%;
  /* padding: 30px 20px; */
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* justify-content: space-between;
  margin: auto; */
}
