.SelectInput select {
  border-radius: 5px;
  border-color: #bdbdbd;
}

.dateInputDiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .dateInput {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.5rem;
    font-size: 16px;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 0.3rem;

    &:focus {
      outline: none;
      border-color: #007bff;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
  }
}