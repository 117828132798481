* {
  font-family: "Montserrat", sans-serif !important;
}

#root {
  height: 100vh;
  width: 100vw;
}

/* html,
body,
#app,
#app > div {
  height: 100%;
  width: 100%;
} */

body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
