.mainNavBar {
  background-image: linear-gradient(
    324deg,
    rgba(156, 39, 175, 1) 47%,
    rgba(63, 81, 181, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
}

.header-content {
  display: flex;
  align-items: center;
}

.welcome-text {
  margin-right: 20px;
  margin-left: 20px;
  color: #ffffff; 
}

.language-btn {
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
  color: #ffffff; 
  background-color: #2196f3;
}

.language-btn {
  background-color: #4caf50; 
}