.overviewCard {
  background-color: rgb(235, 240, 240);
  border: 1px solid skyblue;
  border-radius: 10px;
  height: 200px;
  width: 500px;
  margin-top: 25vh;
  margin-left: 40vh;

  .overviewCardTitle {
    padding: 1% 1% 1% 1%;
    background-color: rgb(144, 211, 242);
    border-radius: 10px;
    font-size: large;
    margin-bottom: 15px;
  }

.overviewCardButtons{
display: flex;
flex-grow: 1;
flex-shrink: 1;
justify-content:center;
gap: 2rem;
}

.overviewCardInputPlusDDButton{
  width: 100%;
  display: flex;
  justify-content:center;
  margin-bottom: 20px;
}
}
